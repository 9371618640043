<template>
	<v-app id="main-app">
	<v-container class="fill-height" fluid>
		<v-card class="mx-auto px-10 pb-9 login-card" elevation="6" width="500px">
			<v-alert
                v-if="alertSuccess"
                dense
                text
                type="success"
                >
                {{$t('success')}}
            </v-alert>
            <v-alert
                v-if="alertError"
                dense
                text
                type="error"
                >
                {{$t('failed')}}
            </v-alert>
			<span class="my-3">
			<button @click="language('en')" style="color: #6600FF;" class="mx-1 pa-0">{{ $t("en") }}</button>
			<button @click="language('es')" style="color: #6600FF;" class="mx-1 pa-0">{{ $t("es") }}</button>
			</span>
			<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
			<v-card-subtitle class="text-center py-1 title-login" style="font-size: 20px">{{$t('welcomeToVitalCheckUps')}}</v-card-subtitle>
			<v-card-subtitle class="text-center title-login primary--text pt-0" style="font-size: 16px">{{$t('moto')}}</v-card-subtitle>
            <v-card-subtitle class="text-center title-login pt-0" style="font-size: 16px; color:red !important">{{$t('account-deletion-request-info')}}</v-card-subtitle>
			<alert-list-auth />
			<v-card-text class="text-center">
				<v-form ref="form1">
					<v-text-field
                        v-model="email"
						ref="email"
						v-bind:label="$t('email')"
						name="email"
						type="email"
                        prepend-inner-icon="mdi-email mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.emailRequired, rules.email]"
                        @keyup.enter="clickButton"
                    />
				</v-form>
			</v-card-text>
			<v-card-actions class="pb-10">
				<v-spacer />
				<v-btn color="primary" large class="ma-0 pa-1" style="border-radius: 10px; font-size: 12px;" :loading="loading" :disabled="loading" @click="sentAccountDeletionRequest">
                    <span>{{$t('send-account-delition-btn')}}</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
	</v-app>
</template>

<script>
import AlertListAuth from '../Client/components/AlertListAuth.vue';
import logoBridge from '../../../public/logo_bridge.png';
import { mapGetters, mapState } from 'vuex';
// import CountryFlag from 'vue-country-flag';

const { LOGIN_LOGO } = require('@/themes/VitalCheckups/image-paths');

export default {
	components: {
		AlertListAuth,
		// CountryFlag,
	},
	data () {
		return {
			loading: false,
			logo: LOGIN_LOGO,
			showPassword: false,
			logo_bridge: logoBridge,
			langs: [{ id: 'en', name: 'English' }, { id: 'es', name: 'Español' }],
			rules: {
				emailRequired: value => !!value || this.$t('emailRequiredMaterial'),
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Enter a valid e-mail address';
				},
				// passwordRequired: value => !!value || this.$t('passwordEnterPassword'),
				passwordRequired: value => !!value || this.$t('passwordEnterPassword'),
			},
			email: '',
			alertError: false,
			alertSuccess: false,
		};
	},
	computed: {
		...mapGetters({ delay: 'authentication/getDelay' }),
		...mapState({
			userDetails: (state) => state.authentication.userData,
		}),
	},
	mounted () {
		if (this.$store.getters['authentication/hasStoredSession']) {
			this.$store.dispatch('authentication/doLogout');
			this.$store.commit('alerts/clear');
			localStorage.clear();
		}
		this.$i18n.locale = 'en';
	},
	methods: {
		language (val) {
			this.$cookies.set('language', val, '6m');
			this.$i18n.locale = this.$cookies.get('language');
		},
		redirectToWebsiteTerms () {
			window.open('https://www.vitalcheckups.com/vitakcheckups_website_terms_of_use_rw_4_28_2022.html', '_blank');
		},
		redirectToPrivacyTerms () {
			window.open('https://www.vitalcheckups.com/vitalcheckups_website_privacy_policy_rw__4_28_2022.html', '_blank');
		},
		async sentAccountDeletionRequest () {
			const accountDeletionRequest = {
				email: this.email,
			};
			await this.$store.dispatch('users/sendProfileRemovalEmail', accountDeletionRequest)
				.then(async (res) => {
					try {
						if (res.resFlag === true) {
							this.alertError = false;
							this.alertSuccess = true;
						} else {
							this.alertSuccess = false;
							this.alertError = true;
						}
					} catch (error) {
						this.alertSuccess = false;
						this.alertError = true;
					}
				});
		},
	},
};
</script>
<style scoped>
    #main-app {
        background: linear-gradient(#E8E8E8, #d6d6d6);
    }
</style>
